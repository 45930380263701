/* src/components/post/post.css */
.post {
    background-color: rgba(36, 41, 89, 0.8) !important;
    transition: all 0.3s ease-in-out;
    border: 1px solid rgba(82, 87, 255, 0.2) !important;
    backdrop-filter: blur(10px);
}

.post:hover {
    transform: translateY(-2px);
    border-color: rgba(82, 87, 255, 0.4) !important;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2) !important;
}

.vote-bar {
    background-color: rgba(46, 49, 93, 0.4);
    border-right: 1px solid rgba(82, 87, 255, 0.2);
    padding: 1.5rem 0;
}

.vote-button {
    cursor: pointer;
    padding: 8px;
    border-radius: 8px;
    transition: all 0.2s ease;
    color: rgba(255, 255, 255, 0.6);
}

.vote-button:hover {
    background-color: rgba(82, 87, 255, 0.1);
    color: rgba(255, 255, 255, 0.9);
}

.vote-button.active.vote-up {
    color: #00ff8c;
}

.vote-button.active.vote-down {
    color: #ff6b6b;
}

.votes-count {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0.75rem 0;
    color: #fff;
}

.post-title {
    color: #fff !important;
    font-weight: 600;
    font-size: 1.3rem;
}

.post-content {
    color: rgba(255, 255, 255, 0.8);
    line-height: 1.6;
}

.info-button {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border: 1px solid rgba(255, 255, 255, 0.2);
    transition: all 0.2s ease-in-out;
}

.info-button:hover {
    background-color: rgba(82, 87, 255, 0.2);
    border-color: rgba(82, 87, 255, 0.4);
}

.project-details .detail-item {
    padding: 1rem;
    border-radius: 8px;
    background-color: rgba(46, 49, 93, 0.4);
    border: 1px solid rgba(82, 87, 255, 0.2);
}

.project-details .detail-label {
    display: inline-block;
    min-width: 140px;
    color: rgba(255, 255, 255, 0.8);
}

.project-details .detail-value {
    color: #fff;
}

/* Modal customization */
.modal-3eme-content {
    background: rgba(36, 41, 89, 0.95);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(82, 87, 255, 0.2);
}

.modal-3eme-dialog .modal-header,
.modal-3eme-dialog .modal-footer {
    background-color: rgba(46, 49, 93, 0.4);
}

.modal-3eme-dialog .modal-title {
    color: #fff;
}

.modal-3eme-dialog .btn-close {
    color: #fff;
    text-shadow: none;
    opacity: 0.8;
}

/* Ajoutez ces styles à votre fichier post.css */

.filters-section {
    position: relative;
}

.search-input {
    background-color: rgba(46, 49, 93, 0.4) !important;
    border: 1px solid rgba(82, 87, 255, 0.2) !important;
    color: white !important;
}

.search-input:focus {
    background-color: rgba(46, 49, 93, 0.6) !important;
    border-color: rgba(82, 87, 255, 0.4) !important;
    box-shadow: 0 0 0 0.25rem rgba(82, 87, 255, 0.25);
}

.filters-zone {
    padding: 1rem;
    background: rgba(36, 41, 89, 0.8);
    border: 1px solid rgba(82, 87, 255, 0.2);
    border-radius: 8px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
}

.filter-select {
    background-color: rgba(46, 49, 93, 0.4) !important;
    border: 1px solid rgba(82, 87, 255, 0.2) !important;
    color: white !important;
}

.filter-select:focus {
    background-color: rgba(46, 49, 93, 0.6) !important;
    border-color: rgba(82, 87, 255, 0.4) !important;
    box-shadow: 0 0 0 0.25rem rgba(82, 87, 255, 0.25);
}

.filter-select option {
    background-color: rgb(36, 41, 89);
    color: white;
}

@media (max-width: 768px) {
    .filters-zone {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 768px) {
    .vote-bar {
        border-right: none;
        border-bottom: 1px solid rgba(82, 87, 255, 0.2);
    }

    .post-title {
        font-size: 1.1rem;
    }

    .modal-3eme-dialog {
        margin: 0.5rem;
        width: auto;
        min-width: auto;
    }
}
