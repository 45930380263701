@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

/* Global Styles */
html, body {
  margin: 0;
  padding: 0;
  min-height: 100vh; /* Assure que le body occupe au moins la hauteur de la fenêtre */
  scroll-behavior: smooth;
  font-family: "Outfit", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  color: #efefef;
  background: linear-gradient(212deg, rgba(62,69,134,1) 0%, rgb(14, 27, 44) 100%) !important;
  background-repeat: no-repeat;
  background-attachment: fixed; /* Fixe le background lors du scroll */
}

.form-control[readonly] {
  background-color: #e9ecef; /* Gris clair */
  opacity: 1; /* Assure une opacité constante */
  cursor: not-allowed; /* Indique que le champ est inactif */
}

.bg-gradient-primary {
  background-color: #2B3065;
  background-image: linear-gradient(180deg, rgba(36, 41, 89, 0.8) 10%, rgba(46, 53, 124, 0.8) 100%);
  background-size: cover;
  background-attachment: fixed;
}

:root {
  --swiper-theme-color: rgb(255, 255, 255) !important;
  --scrollbar-width: 16px;
}

/* Visibility */
.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
  display: none;
}

/* Progress Bar */
.progressbar.green svg {
  margin-top: 16px;
  border-radius: 6px !important;
  height: 4px !important;
  background-color: #fff;
}

.progressbar.green svg path:last-child {
  stroke: #00ff8c;
}

.progress-list .progressbar svg, .progress-list .progressbar svg path {
  border-radius: 1rem;
}

.container {
  margin-bottom: 10px;
}

.progress-container {
  height: 12px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  margin: 0;
}

.progress-bar, .progress-bar-complet {
  height: 100%;
  border-radius: 5px;
  transition: width 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 4px;
  font-size: 0.8em;
}

.progress-bar {
  background: linear-gradient(0deg, rgba(69, 196, 160, 1) 0%, rgba(0, 255, 140, 1) 100%);
}

.progress-bar-complet {
  background: linear-gradient(0deg, rgb(94, 61, 192) 0%, rgb(155, 122, 255) 100%);
}

/* Animations */
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes taadaa {
  0% {
    filter: brightness(800%);
  }
  100% {
    filter: brightness(100%);
  }
}

/* Default Element Styles */
* {
  word-spacing: normal !important;
  letter-spacing: normal !important;
  scroll-behavior: smooth;
}

.scroll-reveal {
  opacity: 0;
  transform: translateY(420px) scale(2);
  filter: blur(6px);
  transition: opacity 0.8s, transform 0.8s, filter 0.8s;
}

.scroll-reveal.is-visible {
  opacity: 1;
  transform: translateY(0) scale(1);
  filter: blur(0);
}

hr {
  border-color: rgba(255, 255, 255, 0.2);
}

p {
  font-weight: 200 !important;
}

a {
  color: #fff !important;
  text-decoration: none;
}

a:hover {
  color: #5257ff !important;
}

.link-dark:hover, a.text-reset:hover, .text-reset a:hover {
  color: #a96eff !important;
}

strong {
  font-weight: 600;
}

.logo-3emelieu {
  max-width: 184px;
  margin: 32px 0;
}

section {
  margin-top: 64px;
  margin-bottom: 10vh;
}

.offcanvas {
  bottom: 0;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  box-shadow: var(--bs-offcanvas-box-shadow);
  transition: transform 0.3s ease-in-out;
  z-index: 99999;
  height: 100% !important;
}

.bg-blog-card-1 {
  width: 100%;
  height: 320px !important;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tier-card {
  cursor: pointer;
}

.btn-close {
  padding: 0;
  color: #ffffff;
  background: none;
  border: 0;
  line-height: 1;
  transition: all 0.2s ease-in-out;
}

.bg-3emelieu {
  background: rgba(52, 58, 122, 0.8);
  margin-top: 64px;
  margin-bottom: 256px;
  padding-top: 60px;
  padding-bottom: 60px;
  backdrop-filter: blur(32px);
  box-shadow: inset 0 0 16px rgba(82, 67, 166, 0.3);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.07);
}

.bg-3emelieu-2, .bg-3emelieu-4 {
  background: rgba(36, 41, 89, 0.8);
  background: linear-gradient(45deg, rgba(36, 41, 89, 0.8) 70%, rgba(65, 78, 195, 0.3) 100%);
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 14px;
  backdrop-filter: blur(32px);
  border-radius: 6px;
  border-top: 1px solid rgba(121, 133, 255, 0.2);
}

.bg-sensei {
  background: rgba(55, 185, 207, 0.75);
  margin-top: 120px;
}

.pill-bg-sensei, .pill-bg-dshappiness {
  --bs-bg-opacity: 1;
  margin: 4px 0 8px;
}

.bg-dshappiness {
  background: rgba(198, 18, 88, 0.8);
  margin-top: 120px;
}

.pill-bg-sensei {
  background-color: rgba(55, 185, 207, 0.75) !important;
}

.pill-bg-dshappiness {
  background-color: rgba(198, 18, 88, 0.8) !important;
}

.pill-border-dshappiness, .pill-border-sensei {
  --bs-bg-opacity: 0;
  margin: 3px !important;
}

.pill-border-dshappiness {
  border: 2px solid rgba(198, 18, 88, 0.8) !important;
}

.pill-border-sensei {
  border: 2px solid rgba(55, 185, 207, 0.75) !important;
}

.bg-tierslieu-dark, .bg-sensei-dark, .bg-dshappiness-dark {
  background: rgba(43, 44, 52, 0.75);
  margin-top: 120px;
  background-size: 30%;
  background-position: right center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-blend-mode: overlay;
}

.bg-tierslieu-dark {
  /* background-image: url("https://3emelieu.ch/img/svg/3emelieu-line.svg"); */
}

.bg-sensei-dark {
  /* background-image: url("https://3emelieu.ch/img/svg/sensei-line.svg"); */
}

.bg-dshappiness-dark {
  /* background-image: url("https://3emelieu.ch/img/svg/dshappiness-line.svg"); */
}

.avatar-grayscale {
  filter: grayscale(100%);
}

.infosBar span {
  margin: 0 4px;
}

canvas {
  width: 100%;
  height: 100%;
  animation: fadein 16s;
  filter: blur(0px);
  mix-blend-mode: overlay;
}

#canvas {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  max-width: 100vw;
  max-height: 100vh;
}

.bg-top {
  position: absolute;
  z-index: -1;
  top: 0;
  width: 100vw;
  height: 120vh;
  background-image: linear-gradient(rgba(40, 45, 94, 0.8) 0%, rgba(40, 45, 94, 0) 50%);
}

.bg-top2 {
  position: absolute;
  z-index: -1;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-image: radial-gradient(at center, rgba(40, 45, 94, 0.7) 0%, rgba(40, 45, 94, 0.7) 20%, rgba(40, 45, 94, 0) 50%);
}

.page-loader {
  background-color: rgb(40, 45, 94);
  /* background-image: url("/img/bg.svg"); */
  background-size: cover;
  background-position: center;
  animation: fadein 1s;
  color: #fff;
}

.color-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: -11;
  background: radial-gradient(farthest-side at center left, rgba(167, 35, 83, 0.1), transparent 100vh), radial-gradient(farthest-side at center center, rgba(55, 185, 207, 0.15), transparent 30vh), radial-gradient(farthest-side at left center, rgba(40, 45, 94, 0.3), transparent 200vh), radial-gradient(farthest-side at center center, rgba(109, 183, 199, 0.2), transparent 100vh);
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #fff !important;
  word-spacing: 0.1rem;
  letter-spacing: 0.1rem;
}

h2, h4 {
  color: #fff;
}

h4 {
  font-size: 1.3em;
}

.h4-slug {
  font-weight: bold;
  border-bottom: 2px solid #5257ff;
  margin: 10px 10px 32px 10px;
}

.h1-slug {
  margin-top: 20vh;
  font-size: 4em;
  align-items: center;
  text-shadow: 0px -2px 12px rgba(82, 87, 255, 0.8);
}

.certificat-type-h3 {
  margin: 6px 0;
  border-radius: 12px;
  width: 100%;
  background: linear-gradient(90deg, rgba(82, 87, 255, 1) 0%, rgba(82, 87, 255, 0) 100%);
  color: #fff;
  padding-left: 10px;
}

.process-certificate {
  font-size: 0.9em;
  list-style: none;
}

.process-certificate ul {
  list-style: none;
  margin-left: 5px;
  padding-left: 0;
}

.process-certificate li {
  padding-left: 1em;
  text-indent: -1em;
}

.process-certificate li:before {
  content: "⦿";
  padding-right: 5px;
  color: #5257ff;
}

label {
  font-size: 1.1em !important;
  color: #fff;
}

.input, .select, .textarea {
  background-color: #60697b !important;
}

input, .form-control {
  font-size: 1.1em !important;
  vertical-align: center;
  color: #fff !important;
}

.form-control:focus {
  color: #60697b;
  background-color: rgba(43, 48, 101, 0.5);
  border: 2px solid #eee !important;
  outline: 0;
  box-shadow: 0rem 0rem 1.25rem rgba(30, 34, 40, 0.04), unset;
}

.htags {
  font-size: 0.7em;
  white-space: nowrap;
  text-transform: capitalize;
  margin: 0;
  padding: 0;
}

.htags:before {
  content: '#';
  font-size: 1.4em;
  padding-right: 0;
  margin-right: 0;
  margin-left: 10px;
  color: rgba(255, 255, 255, 0.2);
}

.display-1, .display-2 {
  font-weight: 500;
}

.body-bg {
  display: none;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: -10;
  top: 0;
  margin: 0;
  background: radial-gradient(farthest-side at top right, rgba(109, 183, 199, 0.2), transparent 70vw), radial-gradient(farthest-side at top center, rgba(40, 45, 94, 0.8), transparent 60vw), radial-gradient(farthest-corner at bottom right, rgba(167, 35, 83, 0.1), transparent 60vw), radial-gradient(farthest-corner at center right, rgba(167, 35, 83, 0.06), transparent 70vw), radial-gradient(farthest-corner at bottom center, rgba(167, 35, 83, 0.06), transparent 70vw);
}

.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: #5257ff;
  border-color: #5257ff;
  box-shadow: var(--bs-btn-active-shadow);
}

.btn-primary-3emelieu {
  --bs-btn-color: #fff;
  --bs-btn-bg: #464290;
  border: 2px solid #fff !important;
  --bs-btn-hover-color: #fff !important;
  --bs-btn-focus-shadow-rgb: 92, 140, 229;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #464290;
  --bs-btn-active-border-color: #464290;
  --bs-btn-active-shadow: 0rem 0.25rem 0.75rem rgba(30, 34, 40, 0.15);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #464290;
  --bs-btn-disabled-border-color: #464290;
  --bs-btn-lighter: #5257ff;
}

.btn:hover {
  color: #fff !important;
  background-color: rgba(255,255,255,0.1) !important;
}

.text-white {
  color: #fff !important;
}

.form-check-input {
  background-color: #5257ff;
  border: 2px solid rgba(255, 255, 255, 0.7);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 1.2rem;
  --bs-nav-link-font-size: 0.8rem;
  --bs-nav-link-font-weight: 700;
  --bs-nav-link-color: #fff !important;
  --bs-nav-link-hover-color: #fff !important;
  --bs-nav-link-disabled-color: #fff !important;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-opacity {
  opacity: 0.6;
}

.navbar.navbar-bg-light {
  box-shadow: none;
}

.navbar-fixed {
  position: fixed;
  z-index: 1;
  width: calc(100vw - var(--scrollbar-width)) !important;
  height: 60px;
  background: rgba(40, 40, 40, 0.3) radial-gradient(farthest-side at top right, rgba(198, 18, 88, 0.08), transparent 120vw);
  box-shadow: 0rem 0rem 1.25rem rgba(30, 34, 40, 0.08);
  backdrop-filter: blur(30px);
  filter: contrast(0.9) brightness(1.1) saturate(1.9);
  animation: slideDown 300ms ease-in-out alternate;
  border-bottom: 1px solid rgba(255, 255, 255, 0.06);
}

.header-nav-3 {
  position: relative;
  width: 100%;
  background-color: transparent;
}

.nav-link {
  height: 48px;
  font-weight: 300 !important;
  letter-spacing: 1.4px !important;
  font-size: 1.4em !important;
  color: #fff !important;
  transition: background 0.5s ease;
  border-radius: 0px !important;
}

.sidebar hr.sidebar-divider {
  margin: 0px;
}

.sidebar .nav-item .nav-link span {
  font-size: 1.1rem;
  display: inline;
}

.sidebar #sidebarToggle {
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
  cursor: pointer;
  margin-top: 32px;
}

.nav-link:hover {
  background: linear-gradient(180deg, rgba(96, 101, 255, 0.6) 0%, rgba(82, 87, 255, 0.3) 20%, rgba(82, 87, 255, 0) 100%);
}

.bullet-online, .bullet-paused, .bullet-online-big, .bullet-paused-big {
  border-radius: 45px;
  margin-left: 2px;
}

.bullet-online, .bullet-paused {
  width: 8px;
  height: 8px;
}

.bullet-online {
  background-color: rgb(69, 196, 160);
}

.bullet-paused {
  background-color: rgb(211, 211, 211);
}

.bullet-online-big, .bullet-paused-big {
  width: 24px;
  height: 24px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  margin-top: 4px;
}

.bullet-online-big {
  background-color: rgb(69, 196, 160);
}

.bullet-paused-big {
  background-color: rgb(211, 211, 211);
}

.mega-menu {
  width: 90vw !important;
}

.dropdown-menu {
  margin: 0 !important;
  padding: 2px !important;
}

.mega-menu-content {
  padding: 2px !important;
}

.dropdown-menu {
  background: linear-gradient(0deg, rgba(46, 49, 93, 1) 0%, rgb(30, 30, 65) 100%);
  border-bottom: 1px solid rgba(120, 120, 120, 1);
  box-shadow: 0 1px 2px rgba(12, 14, 34, 0.07), 0 2px 4px rgba(12, 14, 34, 0.07), 0 4px 8px rgba(12, 14, 34, 0.07), 0 8px 16px rgba(12, 14, 34, 0.07), 0 16px 32px rgba(12, 14, 34, 0.07), 0 32px 64px rgba(12, 14, 34, 0.07);
  font-size: 1.1em !important;
  border-bottom: 1px solid #313773;
}

.dropdown-menu a {
  color: #fff !important;
}

.dropdown-menu a:hover {
  color: #756eff !important;
}

.dropdown-header-sensei {
  color: #34a7bc;
  text-align: center;
  margin-top: 0;
}

.dropdown-header-dshappiness {
  color: #C61258;
  text-align: center;
  margin-top: 0;
}

.dropdown-item {
  padding: 0 4px 0 2px;
  font-weight: normal;
  font-size: 0.85em !important;
}

.p-m-sensei, .p-m-dshappiness {
  position: absolute;
  height: 8px;
  width: auto;
  padding-top: 0;
  margin-top: 10px;
  border-radius: 100%;
}

.p-m-sensei {
  border-right: 4px solid #37B9CF;
}

.p-m-dshappiness {
  border-left: 4px solid #C61258;
}

.p-m-sensei-full + *, .p-m-dshappiness-full + * {
  border: 2px solid #37B9CF;
  border-radius: 16px;
}

.p-m-dshappiness-full + * {
  border: 2px solid #C61258;
}

.h2-m-sensei + *, .h2-m-dshappiness + * {
  font-weight: 900 !important;
  font-size: 1.2em !important;
  border-top: 1px solid rgba(30, 30, 30, .1);
  text-align: center;
  margin-top: 6px;
}

.p-m-all {
  margin-left: 2px;
}

.modules-sensei, .modules-dshappiness {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  flex-wrap: nowrap;
}

.form.dark-fields .form-control:focus {
  border-color: rgba(255, 255, 255, 0.8) !important;
}

.footer-3 {
  margin-top: 120px;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  border-bottom: 10px solid #24264b;
  box-shadow: 0px -1px 10px 2px rgba(226, 99, 232, 0.9), 0px -5px 20px 4px rgba(163, 0, 232, .5), 0px -15px 40px 8px rgba(163, 0, 232, .3), 0px -15px 80px 16px rgba(163, 0, 232, .1), 0px -15px 160px 32px rgba(163, 0, 232, .1), 0px -15px 320px 64px rgba(163, 0, 232, 0.1);
  background: linear-gradient(0deg, rgba(43, 48, 120, 1) 0%, rgba(31, 34, 72, 0.6) 100%);
}

.form-control {
  background-color: rgba(88, 98, 215, 0.2);
  border: 1px solid rgba(88, 98, 215, 0.8);
  backdrop-filter: blur(10px);
}

.footer-3 a {
  color: #fff;
}

.footer-infos-social {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

footer {
  font-weight: 200;
}

.portal-title {
  background-color: rgba(43, 48, 120, 1);
  position: absolute;
  z-index: 9999;
  top: 75%;
  right: -2px;
  text-align: right;
  padding: 0 20px;
  font-size: 2.2em;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  border-right: 2px solid rgb(58, 64, 120);
}

.portal-description {
  background-color: #464290;
  width: 100%;
  padding: 6px 0 6px 12px;
  border-radius: 8px;
  margin-top: 10px;
}

.card {
  border: none !important;
  background-color: transparent !important;
}

.card-title {
  color: #45c4a0;
}

.card-body {
  padding: 20px;
  background: rgba(36, 41, 89, 0.8);
  background: linear-gradient(45deg, rgba(36, 41, 89, 0.8) 70%, rgba(46, 53, 124, 0.8) 100%);
  border-radius: 8px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid rgba(55, 60, 126, 0.4);
  box-shadow:
          2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
          6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
          12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
          22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
          41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
          100px 100px 80px rgba(0, 0, 0, 0.07);
}

.card-header-3emelieu button {
  border: none !important;
  background-color: transparent !important;
  color: #fff !important;
  font-size: 1.4em !important;
  padding: 10px !important;
  text-align: left !important;
}

.container-parallax {
  position: relative;
  perspective: 1px;
  transform-style: preserve-3d;
  height: 100vh;
  overflow-x: hidden;
}

.infos-margin-right {
  margin-right: 16px;
}

.modal-open {
  overflow: hidden;
}

.modal-3eme {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s;
  z-index: 99999 !important;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-3eme-content {
  border-radius: 14px;
  padding: 14px;
  box-shadow: 0 1px 1px rgba(40, 45, 94, 0.12), 0 2px 2px rgba(40, 45, 94, 0.12), 0 4px 4px rgba(40, 45, 94, 0.12), 0 8px 8px rgba(40, 45, 94, 0.12), 0 16px 16px rgba(0, 0, 0, 0.12);
}

.modal-3eme-backdrop {
  z-index: 10 !important;
  display: none;
}

.modal-3eme-dialog {
  margin-top: 10vh;
  margin-bottom: 10vh;
  max-height: 80vh;
  animation: slideIn 0.3s;
  max-width: 60vw !important;
  min-width: 860px;
}

.modal-3eme-dialog-gradient {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 14px;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(40, 45, 94, 0.4) 30%, rgba(40, 45, 94, 0) 100%);
}

.modal-3eme-dialog h2 {
  padding: 80px 0;
  text-align: center;
  font-size: 2.8em;
}

.modal-3eme-h5 {
  margin: 14px 8px 40px;
  text-align: center;
}

.modal-3eme-g {
  display: flex;
  align-content: center;
  flex-direction: row;
}

.modal-3eme-row {
  width: 100%;
  padding: 10px;
  margin: 2px;
}

.table-sm > :not(caption) > * > * {
  padding: 0;
}

.table > :not(caption) > * > * {
  padding: 0.1rem 0.1rem;
  padding-left: 0.3rem;
  background-color: rgba(255, 255, 255, 0.02);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.modal-3eme-content th {
  text-align: left;
  padding-left: 4px;
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: #fff;
  background-color: rgba(23, 27, 77, 0.5);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
  margin: 3px 60px !important;
}

.serp-list-group-item {
  position: relative;
  display: block;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 30px !important;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 4px;
}

.serp-list-group-item ul {
  list-style: none;
  margin: 0;
}

.serp-list-group-item a {
  color: #fff;
  margin: 0;
}

.accordion-header {
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  padding: 4px;
  margin: 2px;
  margin-bottom: 0;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.accordion-header-small {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  padding: 6px 6px 0 6px;
  margin: 4px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
}

.accordion-progress {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.accordion-header-small progress {
  display: flex;
  justify-content: space-around;
}

.accordion-body {
  margin-left: 16px;
  margin-right: 16px;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  background-color: rgba(43, 48, 120, 0.8);
}

.accordion-button {
  cursor: pointer;
  font-size: 1.2em;
}

.mask {
  mask-image: linear-gradient(transparent, black, transparent);
  mask-repeat: no-repeat;
  mask-size: cover;
}

.bg-layer-1, .bg-layer-1-slug, .bg-layer-2, .bg-layer-3, .bg-layer-4, .bg-layer-5, .bg-layer-6, .bg-layer-6-slug {
  position: absolute;
  width: 100%;
  background-repeat: no-repeat;
}

.bg-layer-1, .bg-layer-1-slug, .bg-layer-2, .bg-layer-3, .bg-layer-4 {
  height: 100vmax;
  background-size: 100% 100%;
}

.bg-layer-1 {
  transform: translate3d(0, -50px, -1px) scale(2.2);
  z-index: -3;
  mix-blend-mode: lighten;
}

.bg-layer-1-slug {
  transform: translate3d(0, -50px, -0.5px) scale(1.8);
  z-index: -3;
  filter: blur(2px) saturate(1.4);
}

.bg-layer-2 {
  transform: translate3d(0, 160vh, -1px) scale(2.0);
  z-index: -4;
  mix-blend-mode: lighten;
  opacity: 1;
}

.bg-layer-3 {
  top: 100vh;
  margin-top: 70vmax;
  transform: translate3d(0, 250vh, -4px) scale(5);
  z-index: -5;
  opacity: 0.6;
}

.bg-layer-4 {
  bottom: 0;
  margin-bottom: 0;
  mix-blend-mode: screen;
}

.bg-layer-5 {
  z-index: -1;
  top: 0;
  transform: translate3d(0, 65vh, -1px) scale(1);
  background-position: center;
  background-size: 100% auto;
  mix-blend-mode: color-dodge;
}

.bg-layer-6 {
  z-index: -2;
  bottom: 0;
  transform: translate3d(0, 0, -4px) scale(5);
  background-position: bottom;
  background-size: 100% auto;
  mask-image: linear-gradient(transparent, black, transparent);
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
}

.bg-layer-6-slug {
  z-index: -2;
  bottom: 0;
  transform: translate3d(0, 10vh, -1px) scale(2);
  background-position: top;
  background-size: 100% auto;
  filter: blur(6px) saturate(1.4);
  mask-image: linear-gradient(transparent, black, transparent);
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
}

.search-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.search-filter-btn {
  width: fit-content;
  background-color: rgba(255, 255, 255, 0.2);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  margin-left: 10px;
}

.search-img {
  flex: 1 8 auto;
  max-height: 92px;
  padding: 2px;
  border-radius: 450px;
  border: 4px solid rgba(255, 255, 255, 0.1);
  margin-right: 20px;
}

.col-events {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-around;
}

.col-event {
  flex: 1 0;
  margin: 0.75rem;
}

.JPO {
  border-radius: 45px;
  border: 3px solid #fff;
  color: #000;
  background-color: #00ff8c;
  padding: 4px 32px;
  margin: 6px;
  text-align: center;
  max-width: 320px;
  font-size: 1.1em;
  font-weight: 800;
}

/* Portfolio Images Gallery */
.prtfo-container {
  padding: 20px;
  background-color: #1b1e34;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.prtfo-gallery-title {
  color: #ffffff;
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.prtfo-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

.prtfo-gallery-item {
  flex: 1 1 calc(25% - 16px);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.2s, box-shadow 0.2s;
  border-radius: 8px;
  overflow: hidden;
}

.prtfo-gallery-item:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.prtfo-gallery-image {
  width: 100%;
  height: auto;
  transition: transform 0.2s;
}

.prtfo-gallery-item:hover .prtfo-gallery-image {
  transform: scale(1.1);
}

.bg-container-crowdfunding {
  border-radius: 15px;
  box-shadow:
          2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
          6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
          12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
          22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
          41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
          100px 100px 80px rgba(0, 0, 0, 0.07);
  background-size: cover;
}


@media (max-width: 768px) {
  .prtfo-gallery-item {
    flex: 1 1 calc(50% - 16px);
  }
}

@media (max-width: 576px) {
  .prtfo-gallery-item {
    flex: 1 1 calc(100% - 16px);
  }
}

/* Responsive Styles */
@media (min-width: 1281px) {
  /* Custom styles for large screens */
}

@media (min-width: 1025px) and (max-width: 1280px) {
  /* Custom styles for laptops and desktops */
}

@media (min-width: 992px) {
  .col-lg-3 {
    flex: 0 0 auto;
    width: 30% !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  /* Custom styles for tablets and iPads in portrait mode */
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* Custom styles for tablets and iPads in landscape mode */
}

@media (min-width: 481px) and (max-width: 767px) {
  /* Custom styles for low resolution tablets and mobiles in landscape mode */
}

@media (min-width: 320px) and (max-width: 480px) {
  :root {
    --scrollbar-width: 0px;
  }

  .navbar-fixed {
    padding: 0 10px;
  }

  .h1-slug {
    margin-top: 200px !important;
    font-size: 2.5em;
    text-align: center;
  }

  .h3-slug {
    margin-top: 4vh;
    text-align: center;
  }

  .modal-3eme-dialog {
    margin-top: 10vh;
    margin-bottom: 10vh;
    max-height: 80vh;
    animation: slideIn 0.3s;
    min-width: 100vw;
  }

  .modal-3eme-row {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .desktop-hide {
    display: block;
  }

  .mobile-hide {
    display: none;
  }

  .mobile-margin {
    margin: 0 8px;
  }

  section {
    margin-bottom: 120px !important;
  }

  .mobile-column {
    flex-direction: column;
  }

  .infos-margin-right {
    margin-right: 0;
  }

  .logo-3emelieu {
    max-width: 128px;
    margin: 32px 0;
  }

  .bg-layer-mobile {
    margin-top: 0 !important;
    margin-bottom: 40vh !important;
  }

  .bg-layer-6 {
    transform: translate3d(0, -20vh, -1px) scale(2.7);
    top: -20vh;
    filter: blur(0px) saturate(1.1);
  }

  .bg-layer-5 {
    margin-top: 120vh;
  }

  .bg-layer-1 {
    visibility: hidden;
  }

  .bg-layer-2 {
    margin-top: 100% !important;
    width: 100vw;
    height: 300vw;
    transform: translate3d(0, 400vh, -1px) scale(3);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    mix-blend-mode: unset;
    filter: unset;
  }

  .bg-layer-3 {
    top: -40vh !important;
  }

  .bg-3emelieu {
    margin-top: 4vh !important;
  }

  .pill-bg-sensei, .pill-bg-dshappiness {
    font-size: 1em;
  }

  .btn {
    padding: 0.5rem !important;
  }

  .btn-into {
    width: 90vw;
  }

  .btn-small {
    font-size: 1.0em !important;
    font-weight: normal !important;
    padding: 4px 10px !important;
  }

  .btn-service {
    justify-content: center;
  }

  input {
    padding: 0.5rem !important;
  }

  .p-10 {
    padding: 1.5rem !important;
  }

  .p-9 {
    padding: 1.25rem !important;
  }

  .p-course {
    font-weight: 100;
    text-align: justify;
    margin: 4px;
    text-justify: distribute;
  }

  .footer-3 {
    padding: 12px;
    margin-bottom: 32px;
  }

  .portal-title {
    top: 10%;
    font-size: 1.3em;
  }

  .modules-sensei, .modules-dshappiness {
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
  }

  .module-frame {
    padding: 16px;
  }

  .accordion-progress {
    flex-direction: column;
    margin: 4px;
  }

  .accordion-collapse {
    transition: height 0.3s ease-in-out;
  }

  .collapse.show {
    height: auto;
  }

  .offcanvas {
    position: absolute;
    bottom: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    visibility: hidden;
    background-color: rgba(31, 34, 72, 0.9) !important;
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: transform 0.3s ease-in-out;
    border-left: 1px solid rgba(55, 60, 126, 0.9) !important;
  }

  .offcanvas-body {
    height: auto;
    bottom: 0;
    z-index: 99999 !important;
  }

  .offcanvas-footer {
    left: 0;
    bottom: 0;
    display: flex;
    flex-shrink: 0;
    margin-top: auto;
    padding: 1.5rem 0;
  }

  .dropdown-menu {
    background: unset !important;
    border-bottom: unset !important;
    box-shadow: unset !important;
    font-size: unset !important;
  }

  .flex-mobile {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .bg-tierslieu-dark, .bg-sensei-dark, .bg-dshappiness-dark {
    background-size: 70%;
    background-position: bottom right;
  }

  .tierslieu-intro-bg, .sensei-intro-bg, .dshappiness-intro-bg {
    margin-top: 32px;
    width: 320px;
    height: 320px;
  }

  .tierlieu-espace {
    flex-wrap: wrap;
  }

  .table {
    --bs-table-color: #fff !important;
    --bs-table-striped-color: #fff !important;
    border-color: rgba(255, 255, 255, 0.5) !important;
    color: #fff !important;
  }

  .table > :not(caption) > * > * {
    padding: 0.4rem 0.2rem !important;
  }

  .table:not(.table-borderless) tbody {
    border: none;
  }

  thead, tbody, tfoot, tr, td, th {
    border: solid 0;
  }

  .table > :not(caption) > * > * {
    padding: 0.7rem 0.4rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  }

  thead, tbody, tfoot, tr, td, th {
    border: none !important;
  }

  .container-table-3emelieu {
    flex-wrap: wrap;
  }

  .table-3emelieu {
    color: #fff;
    border-color: rgba(255, 255, 255, 0.3);
    white-space: wrap;
  }

  .newsletter-mobile-container {
    /* background-image: url('/img/AV1/bg_newdletter_alpha.avif'); */
    background-position: right;
    background-repeat: no-repeat;
    border-radius: 10px;
  }

  .newsletter-mobile {
    background-color: rgba(43, 48, 120, 0.7);
    padding: 4px;
    border-radius: 6px;
    letter-spacing: 0;
    text-align: center;
  }

  .modal-body {
    padding: 10px;
  }

  .modal-h2 {
    margin: 10px 0;
    text-align: center;
    font-size: 2em;
  }

  .modal-h5 {
    margin: 10px 0;
    text-align: center;
  }

  .list-group-item {
    margin: 3px 0 !important;
  }

  .serp-list-group-item {
    margin: 20px 0 !important;
  }

  .serp-list-group-item h4 {
    font-size: 1em;
  }

  .serp-list-group-item ul {
    padding-left: 2px;
  }

  .search-flex {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .search-img {
    margin-right: 10px;
  }

  .bg-3emelieu-4 {
    margin-bottom: 10px;
  }

  .col-events {
    flex-direction: column;
  }

  .col-event {
    margin: 0;
  }

  .matos {
    flex-wrap: wrap;
  }
}

/* Current App Styles */
#wrapper #content-wrapper {
  background-color: rgb(22, 24, 49);
  width: 100%;
  overflow-x: hidden;
}

.navbar {
  background-color: rgb(16, 17, 35);
}

.table-bordered th, .table-bordered td {
  border: 1px solid rgb(107, 107, 107);
}

.cursor-pointer {
  cursor: pointer;
}

.bg-dark {
  background-color: rgb(16, 17, 35) !important;
}


.border-left-primary {
  border-left: 0.25rem solid #5257ff !important;
}

.text-primary {
  color: #5257ff !important;
}

.btn-primary {
  color: #fff;
  background-color: #5257ff;
  border-color: #5257ff;
  margin-bottom: 10px;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus {
  color: #fff;
  background-color: #5257ff;
  border-color: #5257ff;
}

.btn-secondary {
  color: #fff;
  background-color: #5257ff;
  border-color: #5257ff;
  padding: 6px;
  margin: 10px;
  border-radius: 32px;
}

.btn-info {
  padding: 6px;
  margin: 10px;
  border-radius: 32px;
}

.btn-secondary:hover, .btn-secondary:active, .btn-secondary:focus {
  color: #fff;
  background-color: rgba(55, 60, 126, 0.9);
  border-color: #5257ff;
}


.btn-outline-primary {
  color: #5257ff;
  border-color: #5257ff;
}

.btn-outline-primary:hover, .btn-outline-primary:active, .btn-outline-primary.active, .show > .btn-outline-primary.dropdown-toggle, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  color: #fff;
  background-color: #5257ff;
  border-color: #5257ffFF;
  box-shadow: 0 0 0 0.2rem rgba(71, 166, 153, 0.38);
}

.btn-main {
  position: relative;
  padding: 6px 30px 12px;
  border: 1px solid transparent;
  background: #5257ff;
  color: #fff;
  overflow: hidden;
}

.btn-main:hover {
  border-color: #5257ff;
}

.btn-main:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #5257ff;
  z-index: -1;
  transition: transform 0.5s;
}

.btn-main:hover:after {
  transform: translate(3px, 6px) scale(1.05);
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #5257ff;
}

.badge-primary {
  color: #fff;
  background-color: #5257ff;
}

.page-link {
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #5257ff !important;
  background-color: #fff;
  border: 1px solid #dddfeb;
  font-size: 1.2em;
  font-weight: 500;
}

.text-dark {
  color: #eaeaea !important;
}

.shadow {
  box-shadow: rgba(50, 50, 93, 0.02) 0 2px 5px -1px, rgba(0, 0, 0, 0.05) 0 1px 6px -1px !important;
}

.card-header {
  background-color: rgb(16, 17, 35) !important;
  border-bottom: none;
  margin-bottom: 4px;
}

.form-group {
  position: relative;
  margin-top: 30px;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  user-select: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before, .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #5257ff;
}

.custom-file-label {
  margin-top: 0;
  color: #858484;
}

.custom-file-label::after {
  border: none;
  border-radius: 0;
  background: #858484;
  top: 1px;
  color: #fff;
}

.form-label {
  margin-top: 32px;
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;
}

.form-control:focus {
  border-color: #5257ff;
  box-shadow: 0 0 0 0.2rem rgb(69, 76, 191);
}

.btn-link {
  color: #5257ff;
}

.btn-link:hover {
  color: #454cbf;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #a6a6ad !important;
  border-color: #708ee5 !important;
}

.card {
  background-color: #2c2f36;
  border: 1px solid #3a3f47;
  color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card-mover:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card .card-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.card .card-text {
  font-size: 0.9rem;
}

.card .btn-primary {
  color: #fff;
  background-color: #5257ff;
  border-color: #6f73ff;
}

.card .btn-primary:hover {
  color: #fff;
  background-color: #5257ff;
  border-color: #5257ff;
}

.card .btn-primary:active, .card .btn-primary:focus {
  color: #fff !important;
  background-color: #00ff8c !important;
  border-color: #5257ff !important;
}

.card {
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
}


.card-title, .card-text {
  white-space: normal;
}

.btn-main {
  margin-top: auto;
}

.pagination {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 20px !important;
}

.pagination .btn {
  margin: 0 5px;
}

.pagination span {
  font-size: 16px;
}



.dropdown-item {
  padding: 0 4px 0 2px;
  font-weight: normal;
  font-size: 0.85em !important;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color:#eee !important;
  text-align: inherit;
  white-space: nowrap;
  border: 0;
}

.dropdown-menu {
  background: linear-gradient(0deg, rgb(46, 49, 93) 0%, rgb(30, 30, 65) 100%);
  box-shadow:
          2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02),
          6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
          12.5px 12.5px 10px rgba(0, 0, 0, 0.035),
          22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
          41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05),
          100px 100px 80px rgba(0, 0, 0, 0.07);
  font-size: 1.2em !important;
  border: none !important;
}

.alert {
  margin-top: 1rem;
  color: #1c1c1e;
  border-radius: 0.5rem;
}

.alert-warning {
  border: 3px solid #5257ff;
  background-color: #ffcc00;
  color: #000; /* Assure une bonne lisibilité */
  padding: 5px;
  border-radius: 5px;
}

.alert-warning button {
  padding: 3px 7px !important;
  margin: 2px 4px 0px 0px !important;
}

.alert-success {
  border: 3px solid #5257ff;
  background-color: #00ff8c;
  color: #000;
  padding: 5px;
  border-radius: 5px;
}

.alert-info {
  border: 3px solid #5257ff;
  color: #000;
  padding: 5px;
  border-radius: 5px;
}


.form-control:disabled, .form-control[readonly] {
  background-color: rgb(46, 49, 93);
  opacity: 1;
}

.filters-zone {
  display: flex;
  justify-content: space-around;
  margin:14px;
  padding: 8px;
  border-radius: 6px;
  background-color: rgba(46, 49, 93, 0.4);
  border: 1px solid rgba(82, 87, 255, 0.38);
}

/* Sidebar */
.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  color: white;
}

.mobile-menu-items {
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  overflow-y: auto;
}

.mobile-menu-item {
  margin-bottom: 20px;
}

.mobile-menu-item .nav-link {
  color: white;
  font-size: 1.5em;
  text-align: left;
  width: 100%;
}

.mobile-menu-close {
  text-align: center;
}

.mobile-menu-close button {
  width: 100%;
  font-size: 1.2em;
  padding: 10px;
}

/* global.css */

/* Input styles */
.form-control,
.form-select,
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
select,
textarea {
  height: 48px;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  background-color: rgba(46, 49, 93, 0.4);
  border: 1px solid rgba(82, 87, 255, 0.3);
  border-radius: 6px;
  color: white;
  transition: all 0.2s ease-in-out;
}

/* Input hover state */
.form-control:hover,
.form-select:hover {
  background-color: rgba(46, 49, 93, 0.6);
  border-color: rgba(82, 87, 255, 0.5);
}

/* Input focus state */
.form-control:focus,
.form-select:focus {
  background-color: rgba(46, 49, 93, 0.7);
  border-color: rgba(82, 87, 255, 0.8);
  box-shadow: 0 0 0 3px rgba(82, 87, 255, 0.25);
  outline: none;
}

/* Select specific styles */
.form-select {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%235257ff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px;
  padding-right: 2.5rem;
  appearance: none;
}

/* Label styles */
.form-label {
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 0.75rem;
  color: rgba(255, 255, 255, 0.9);
}

/* Group spacing */
.form-group {
  margin-bottom: 1.5rem;
}

/* Placeholder styles */
.form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

/* Disabled state */
.form-control:disabled,
.form-select:disabled {
  background-color: rgba(46, 49, 93, 0.2);
  border-color: rgba(82, 87, 255, 0.1);
  cursor: not-allowed;
  opacity: 0.7;
}

/* Form groups in filters zone */
.filters-zone {
  padding: 2rem;
  gap: 2rem;
}

.filters-zone .form-group {
  margin-bottom: 0;
}

.filters-zone .form-label {
  margin-bottom: 1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .form-control,
  .form-select {
    height: 44px;
    padding: 0.625rem 0.875rem;
    font-size: 0.95rem;
  }

  .filters-zone {
    padding: 1.5rem;
    gap: 1.5rem;
  }
}

@media (max-width: 768px) {
  .filters-zone {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 14px;
    padding: 8px;
    border-radius: 6px;
    background-color: rgba(46, 49, 93, 0.4);
    border: 1px solid rgba(82, 87, 255, 0.38);
  }

  .filters-zone > * {
    margin-bottom: 8px; /* Adds space between the filters */
    width: 100%; /* Makes each filter element take up full width */
  }

  .filters-zone > *:last-child {
    margin-bottom: 0; /* Removes margin from the last element */
  }

  .filters-zone button {
    text-align: left; /* Aligns text inside the buttons to the left */
  }

  .card-body {
    margin: 0px;
  }
}

