/* Personnalisation de la barre d'outils */
.custom-quill .ql-toolbar {
    background-color: #282c34; /* Couleur de fond sombre */
    border: 1px solid #4a4e69; /* Bordure douce */
    border-radius: 8px; /* Coins arrondis */
}

/* Icônes et boutons de la barre d'outils */
.custom-quill .ql-toolbar button {
    color: #ffffff; /* Couleur des icônes */
    background: transparent;
    border: none;
    margin: 0 4px;
    transition: all 0.2s ease-in-out;
}

.custom-quill .ql-toolbar button:hover {
    color: #f8b400; /* Couleur au survol */
    transform: scale(1.1); /* Légère mise en avant */
}

/* Zone de texte */
.custom-quill .ql-container {
    background-color: #1e2132; /* Fond sombre */
    color: #ffffff; /* Texte blanc */
    border: 1px solid #4a4e69; /* Bordure douce */
    border-radius: 8px;
    min-height: 150px; /* Hauteur minimale */
    padding: 8px;
    font-size: 16px; /* Taille du texte */
    line-height: 1.6;
}

/* Curseur et sélection */
.custom-quill .ql-editor {
    caret-color: #f8b400; /* Couleur du curseur */
}

.custom-quill .ql-editor::selection {
    background: #f8b400; /* Couleur de surbrillance */
    color: #000000;
}
.project-gallery-image {
    max-width: 100%;
    max-height: 200px; /* Ajustez cette valeur selon vos besoins */
    object-fit: contain;
    margin-bottom: 10px;
}

.project-gallery-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}
.background-preview {
    max-width: 100%;
    margin-top: 1rem;
}

.image-container {
    max-width: 500px; /* Ajustez cette valeur selon vos besoins */
    max-height: 300px; /* Ajustez cette valeur selon vos besoins */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
