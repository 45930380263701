/* DonorsList.css */

.card {
    margin-bottom: 0px;
    border: none;
    border-radius: 1rem;
    background-color: rgba(82, 87, 255, 0.24);
    color: #ffffff;
}

.card-title {
    font-size: 1.5rem;
    font-weight: bold;
}

.table-dark {
    color: #fff;
    background-color: rgba(82, 87, 255, 0.0);
}

.table {
    margin-top: 4px;
    border-collapse: separate;
    border-spacing: 0 0.5rem;
}

.table thead th {
    background-color: rgb(22, 24, 49);
    color: #ffffff;
    border: none;
    padding: 0.5rem;
    font-weight: bold;
    text-align: center;
}

.table tbody tr {
    background-color: rgba(82, 87, 255, 0.24);
    border-radius: 16px;
    overflow: hidden;
    display: table-row;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.table tbody td {
    border: none;
    padding: 0.4rem;
    text-align: center;
}

.table thead th:first-child {
    border-top-left-radius: 16px;
}

.table thead th:last-child {
    border-top-right-radius: 16px;
}

.table tbody tr:first-child td:first-child {
    border-top-left-radius: 16px;
}

.table tbody tr:first-child td:last-child {
    border-top-right-radius: 16px;
}

.table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 16px;
}

.table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 16px;
}
