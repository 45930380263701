.mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    color: white;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
}

.mobile-menu.active {
    transform: translateX(0);
}

.mobile-menu-items {
    list-style-type: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
    overflow-y: auto;
}

.mobile-menu-item {
    margin-bottom: 20px;
}

.mobile-menu-item .nav-link {
    color: white;
    font-size: 1.5em;
    text-align: left;
    width: 100%;
    padding: 10px 15px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.mobile-menu-item .nav-link:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.user-profile-section {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.user-info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.user-info img {
    width: 50px;
    height: 50px;
    margin-right: 15px;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.3);
}

.user-name {
    font-size: 1.5em;
    font-weight: 600;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.mobile-menu-close {
    text-align: center;
    margin-top: 20px;
}

.mobile-menu-close button {
    width: 100%;
    font-size: 1.2em;
    padding: 15px;
    border-radius: 25px;
    background-color: #5257ff;
    color: white;
    border: none;
    transition: background-color 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.mobile-menu-close button:hover {
    background-color: #5257ff;
}
