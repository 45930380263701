/* styles.css */

body {
    background-color: #2f3136;
    color: #ffffff;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

.talk-container {
    display: flex;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.talk-sidebar, .talk-chat, .talk-members {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.talk-sidebar {
    background-color: #2f3136;
    width: 250px;
    overflow-y: auto;
}

.talk-chat {
    flex-grow: 1;
    background-color: #36393f;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.talk-members {
    background-color: #2f3136;
    width: 250px;
    overflow-y: auto;
}

.talk-chat-header, .talk-chat-footer {
    padding: 10px;
    background-color: #2f3136;
    color: #ffffff;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #202225;
}

.talk-chat-body {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.talk-message {
    margin-bottom: 20px;
}

.talk-message-author {
    font-weight: bold;
    margin-bottom: 5px;
}

.talk-message-content {
    margin-bottom: 10px;
}

.talk-message-reply {
    margin-left: 20px;
}

.talk-message-actions {
    display: flex;
    align-items: center;
}

.talk-message-actions button {
    margin-right: 10px;
}

.talk-gif-container img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
    transition: transform 0.2s;
}

.talk-gif-container img:hover {
    transform: scale(1.1);
}

.talk-channel-list,
.talk-member-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.talk-channel-list li,
.talk-member-list li {
    padding: 5px 10px;
    cursor: pointer;
}

.talk-channel-list li:hover,
.talk-member-list li:hover {
    background-color: #393c43;
}

.talk-channel-list li.active,
.talk-member-list li.active {
    background-color: #7289da;
    color: #ffffff;
}
