.crowdfunding-bg {
    background-color: #1c1c3c;
    color: white;
    min-height: 100vh;
    padding: 60px 0;
}

.crowdfunding-card {
    background-color: #23234d;
    border-radius: 12px;
}

.custom-progress-bar {
    height: 46px;
}
.progress {
    height: 1.4rem;
}

.progress-bar, .progress-bar-complet {
    font-size: 1.3em;
}

.custom-progress-bar .progress-bar {
    min-width: 32px;
    background: linear-gradient(180deg, #00ff8c, #13855c);
    box-shadow:
            0px 0px 60px 45px rgba(45, 255, 196, 0.4), /* Outer shadow */
            inset 0px 0px 5px 2px rgba(63, 255, 166, 0.7); /* Inner shadow */
}


.bg-gradient-success {
    background-color: #36364DFF;
    background-image: linear-gradient(180deg, #2a2f81 10%, #454cbf 100%);
    background-size: cover;
    border: 1px solid #454cbf;
    box-shadow:
            0px 0px 30px 25px rgba(74, 77, 255, 0.2), /* Outer shadow */
            inset 2px 0px 3px 1px rgba(51, 85, 255, 0.4) /* Inner shadow */
}

.bg-cadre {
    margin-bottom: 32px;
    padding: 10px;
    border-radius: 10px;
    background-color: rgba(82,87,255,0.11);
    border: 2px solid #5257ff;
}


.footer {
    background-color: #1c1c3c;
    color: white;
    padding: 20px 0;
}

.infobule-crowdfunding {
    background-color: #454cbf;
    border-radius: 16px;
    padding: 10px;
    margin: 4px;
    font-size: 1.3em;
}

.card-body {
    padding: 0.5rem;
}


/* Check Box */

.custom-checkbox .custom-form-check-input {
    display: inline-flex;
    align-items: center;
}

.custom-checkbox .custom-form-check-input input {
    width: 1.7rem;
    height: 1.7rem;
    border-radius: 0.25rem;
    border: 2px solid #6c757d;
    background-color: #f8f9fa;
    transition: background-color 0.2s, border-color 0.2s;
    cursor: pointer;
    margin-right: 0.5rem; /* Ajoute de l'espace à droite de la checkbox */
}

.custom-checkbox .custom-form-check-input input:checked {
    background-color: #00ff8c;
    border-color: #00ff8c;
}

.custom-checkbox .custom-form-check-input input:focus {
    box-shadow: 0 0 0 0.2rem #00ff8c;;
}



.carousel-container {
    position: relative;
    max-width: 100%;
    overflow: hidden;
}

.carousel-slide {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 100%;
}

.carousel-item {
    min-width: 100%;
    text-align: center;
    position: relative;
}

.carousel-image {
    width: 100%;
    max-height: 500px;
    object-fit: cover;
}

.carousel-caption {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 1em;
}

.btn-close {
border: 1px solid #fff;
    border-radius: 320px;
    padding: 4px;
    height: 32px;
    width: 32px;
    text-align: center;
    vertical-align: middle;
}

.AccordionHeader button {
    background-color: unset !important;
    border: none;
    color: white;
}
