.modal-fullscreen .modal-dialog {
    max-width: 80%; /* 80% of the screen width */
    margin: auto;
}

.modal-fullscreen .modal-content {
    background-color: #2b2e64; /* Dark background color */
    color: #ddd; /* Light text color */
    border-radius: 10px; /* Rounded corners */
    padding: 10px; /* Some padding inside the modal */
}

.modal-content {
    background-color: #2b2e64; /* Dark background color */
    color: #ddd; /* Light text color */
    border-radius: 10px; /* Rounded corners */
    padding: 10px; /* Some padding inside the modal */
}

.accordion-header-small {
    background-color: #333; /* Darker background color for headers */
    color: #fff; /* White text color */
    padding: 10px; /* Padding for headers */
    border-radius: 5px; /* Rounded corners */
}

.accordion-progress .progress-container {
    background-color: #444; /* Dark background for progress bars */
    border-radius: 5px; /* Rounded corners */
    overflow: hidden; /* Hide overflow */
}

.accordion-progress .progress-bar {
    background-color: #007bff; /* Blue progress bar */
}

.subject {
    background-color: #333; /* Darker background color for subjects */
    color: #fff; /* White text color */
}

.list-group-item {
    background-color: #333; /* Darker background color for list items */
    color: #fff; /* White text color */
    border: none; /* Remove border */
}

.tool-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tool-image {
    max-height: 92px;
    padding: 7px;
    border-radius: 450px;
    border: 4px solid rgba(255, 255, 255, 0.1);
}

.tool-description {
    font-size: 0.8em;
    font-weight: 100;
    text-align: center;
}
